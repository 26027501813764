
.DivisiondivProjects .card{
    height: auto;
    width: 22rem;
    border-radius: 5px;
}
.DivisiondivProjects .card img{
    height: 220px;
   width: 100%;
}
.DivisiondivProjects .card-title{
    height: 100px;
}

.DivisiondivProjects button{
    background-color: #fff;
    color: black;
    text-transform: capitalize;
    width: 100%;
    font-weight: bold;
}
.DivisiondivProjects button:hover{
    background-color: #fff;
    color: black;
}
