
.img-slider{
    width: 100%;
    height: 400px;
}

.img-slide, .img-slide img{
    width: 100%;
    height: 350px;
    border-radius: 5px;
}

.img-slide{
    opacity: .5;
    transform: scale(.6);
    transition: .5s;
}

.img-slide-active{
    opacity: 1;
    transform: scale(1);

}

.arrow{
    font-size: 30px;
    padding: 5px 15px;
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
    display: flex;
    width: 50px;
    position:absolute;
    z-index: 100;
    top:150px; 
    cursor: pointer;
}

.prev{
    left: 0px;
}

.next{
    right:0px;
}