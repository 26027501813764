@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
  padding: 0%;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.menu {
  color: white;
  font-size: 30px;
  border-color: white;
}


.navbar-nav li a {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;

}

.navbar-nav li a:hover {
  color: #37d554;
}


.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #3a5a40 !important;
  color: white !important;
}

.navbar-style {
  /* background-color: #1c4966; */
  background-color: #3a5a40;
  color: white;
}

.carousel-caption h5 {
  font-size: 3rem;
  color: white;
  padding-top: 100px;
  text-align: center;
  text-shadow: 2px 2px 4px black;

}

@media (min-width: 320px) {
  .carousel-caption h5 {
    font-size: 2.3rem;
    padding-top: 70px;
  }
}

.about {
  overflow: hidden;
}

.about .bg_image_1 .content {
  bottom: 32rem;
  height: 80vh;
}

.about .bg_image_1 {
  background-image: url("./assest/images/contact.jpg");
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 100%;
}

.about .bg_image_1 .content h3 {
  font-size: 3rem;
  color: white;
  padding-top: 100px;
  text-align: center;
  padding-top: 100px;
  text-shadow: 2px 2px 4px black;
}


.our-business {
  /* background-color: #f8ecb; */
  overflow: hidden;
}

.our-business .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.our-business .bg_image_1 {
  background-image: url("./assest/images/ourbusiness.jpg");
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 100%;
}

.our-business .bg_image_1 .content h3 {
  font-size: 3rem;
  color: white;
  padding-top: 100px;
  text-align: center;
  padding-top: 100px;
  text-shadow: 2px 2px 4px black;
}

.contactus {
  overflow: hidden;
}

.contactus .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.contactus .bg_image_1 {
  background-image: url("./assest/images/contact1.jpg");
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 100%;
}

.contactus .bg_image_1 .content h3 {
  font-size: 3rem;
  color: white;
  padding-top: 100px;
  text-align: center;
  padding-top: 100px;
  text-shadow: 2px 2px 4px black;
}

.medialinks {
  background-color: #f8ecb8;
  overflow: hidden;
}

.medialinks .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.medialinks .bg_image_1 {
  background-image: url("./assest/images/media.jpg");
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 100%;
}

.medialinks .bg_image_1 .content h3 {
  font-size: 3rem;
  color: white;
  padding-top: 100px;
  text-align: center;
  padding-top: 100px;
  text-shadow: 2px 2px 4px black;
}

.career .bg_image_1 {
  background-image: url("./assest/images/career.jpg");
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 100%;
}

.career .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.career .bg_image_1 .content h3 {
  font-size: 3rem;
  color: white;
  padding-top: 100px;
  text-align: center;
  padding-top: 100px;
  text-shadow: 2px 2px 4px black;
}

.team {
  overflow: hidden;
}

.team .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.team .bg_image_1 {
  background-image: url(https://cdn.pixabay.com/photo/2018/01/19/07/57/shaking-hands-3091906_1280.jpg);
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 100%;
}

.team .bg_image_1 .content h3 {
  font-size: 3rem;
  color: white;
  padding-top: 100px;
  text-align: center;
  padding-top: 100px;
  text-shadow: 2px 2px 4px black;
}

.contact .bg_image_1 {
  background-image: url("./assest/images/contact.jpg");
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 100%;
}

.contact-us-left {
  margin-top: 1px;
  font-size: 18px;
  color: black;
  text-align: center;
}

.contact-us-left img {
  width: 100%;
  height: 509px;
  object-fit: cover;
  border-radius: 4px 0 0 4px;

}


.contact-info {
  display: flex;
  flex-direction: column;
  /* Change the direction to column */
  align-items: center;
  margin-bottom: 20px;
}

.contact-info i {
  font-size: 24px;
  /* Adjust icon size */
}

.contact-label {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  /* Add space between icon and label */
}

.contact-details p {
  margin-top: 5px;
  /* Adjust spacing as needed */
  font-size: 15px;
  color: #555555;
}


.contact-us .contact-us-form {
  padding: 50px 40px;
}

.contact-us .contact-us-form h2 {
  position: relative;
  font-size: 32px;
  color: #333;
  font-weight: 600;
  line-height: 27px;
  text-transform: capitalize;
  margin-bottom: 12px;
  padding-bottom: 20px;
  text-align: left;
}

.contact-us .contact-us-form h2:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 2px;
  width: 50px;
  background: white;
}

.contact-us .contact-us-form p {
  font-size: 14px;
  color: #333;
  font-weight: 400;
  text-align: left;
  margin-bottom: 50px;
}

.contact-us .form {
  margin-top: 30px;
}

.contact-us .form .form-group {
  margin-bottom: 22px;
}

.contact-us .form .form-group input {
  width: 100%;
  height: 50px;
  border: 1px solid black;
  text-transform: capitalize;
  padding: 0px 18px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
}

.contact-us .form .form-group textarea {
  width: 100%;
  height: 100px;
  border: 1px solid black;
  text-transform: capitalize;
  padding: 18px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
}

.section1 {
  /* background-color:#43766c; */
  min-height: 100vh;
  padding: 1rem 9% 6rem;
}

.btn {
  background-color: black;
  border-color: black;
}

.btn:hover {
  background-color: #422311;
  border-color: #422311;
}

.footer {
  background-color: #475877;
  color: white;
}

.footer h3 {
  font-size: 22px;
  font-weight: bold;
}

.footer p {
  font-size: 15px;
}

.footer .ptag {
  font-size: 15px;
  margin-bottom: 15px;
}

.footer i {
  font-size: 30px;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer a:hover {
  color: #344e41;
}


.copyright {
  background-color: black;
  color: #fff;
}

.team-card {
  border-radius: 5px;
  width: 15rem;
  /* height:23rem; */
}

.team-card img {
  width: 15rem;
  height: 15rem;
  border-radius: 5px;
  /* height:23rem; */
}


.card-color {
  background-color: #3a5a40;
  color: white;
}

.business-card1 img {
  height: 350px;
  width: 450px;
}


.business-card {
  width: 40.5rem;
  border-radius: 5px;
  background-color: #3a5a40;
  color: white;
  margin: 55px;
  margin-left: 15rem;
}


@media only screen and (max-width: 1024px) {
  .business-card {
    width: 20rem;
    border-radius: 5px;
    margin: 1px;
    margin-left: 1rem;
  }

  .business-card1 img {
    height: 400px;
    width: 300px;
  }


}

@media only screen and (max-width: 480px) {
  .business-card {
    width: 20rem;
    border-radius: 5px;
    margin: 1px;
    margin-left: 0rem;
  }

  .business-card1 img {
    height: 400px;
    width: 320px;
  }
}

.testimonial-card .card-up {
  height: 120px;
  overflow: hidden;
  border-radius: 5px;
  background-color: #3a5a40;
}

.testimonial-card .avatar {
  width: 110px;
  margin-top: -80px;
  overflow: hidden;
  border: 2px solid #fff;
  border-radius: 50%;
}


.section {
  min-height: 100vh;
  padding: 2rem 9% 6rem;
}

.service {
  padding: 2rem 5%;

}

.service h2 {
  margin-bottom: 5rem;

}

.service-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* align-items: start; */
  gap: 2.5rem;

}

.service-container .service-box {
  /* flex: 1 1 30rem; */
  background: #3a5a40;
  width: 350px;
  padding: 20px 10px;
  border-radius: 5px;
  /* text-align: center; */
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.712);
  /* border:.2rem solid;  */
  transition: .5s ease-in-out;
}

.service-container .service-box:hover {
  background-color: #344e41;
  color: black;
  /* transform: scale(1.02); */
}

.service-box {
  position: relative;
}

.service-box h1 i {
  font-size: rem;
  /* align-items: end; */
  /* color:#966d37; */
  padding-top: 20px;
  padding-left: 20px;
  color: white;
}

.service-box h4 {
  font-size: 1.5rem;
  color: white;
  padding: 10px 0 0 20px;
}

.service-box p {
  /* margin: 1rem 0 6rem; */
  color: white;
  padding-left: 20px;
}

.service-box a {
  margin: 1rem 0 6rem;
  color: white;
  padding-left: 20px;
}


@media (max-width: 1024px) {
  .service-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* align-items: start; */
    gap: 3rem;

  }

  .service-container .service-box {
    background: #3a5a40;
    border-radius: 5px;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.712);
    transition: .5s ease-in-out;
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .service-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* align-items: start; */
    gap: 1rem;

  }

  .service-container .service-box {
    background: #3a5a40;
    border-radius: 5px;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.712);
    transition: .5s ease-in-out;
    padding: 20px;
  }
}