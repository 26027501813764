.gallery-container {
  display: flex;
  flex-wrap: wrap;
}

.gallery-image {
  width: 260px; 
  height: 250px;
  margin: 13px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: -4px 4px 8px rgba(0, 0, 0,0.19);
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactModal__Content {
  position: relative;
  padding: 10px;
  background: black;
  border-radius: 15px;
  outline: none;
}

.selected-image {
  max-width: 100%;
  max-height: 80vh; /* Adjust the max-height as needed */
  display: block;
  margin: 0 auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: white;
}

.close-button:hover {
  color: #ff0000; 
}
