.home {
  overflow: hidden;
}
.imgeLogo{
  height: 80px;
  width: 160px;
  border-radius: 4px;
}
.home .bg_image_1 {
  background-image: url("../assest/images/maintop.jpg");
  background-size: cover;
  height: 900px;
  width: 100%;
  /* background-attachment: fixed; */
}

.home .bg_image_1 ul {
  display: flex;
  gap: 1rem; 
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #18231d;
  list-style-type: none;
  margin-right: 5rem; 
  margin-left: 5rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.home .bg_image_1 ul li {
  margin: 0.3rem; /* Add spacing between list items for smaller screens */
}

.home .bg_image_1 ul li a {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

.home .bg_image_1 ul li a:hover {
  color: #37d554;
}

.background-container {
  position: relative;
  margin-top: 5%;
  margin-left: 14%;
  width: 70%;
  height: 120px;
  background-size: cover;
  background-position: center;

}

.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3a5a40a6;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 5px;

}

.overlay-container h1,
.overlay-container p {
  margin: 0;
  /* padding: 0.3rem; Adjust padding for smaller screens */
  text-align: center;
}

/* Media queries for 768px (tablet) */
@media only screen and (max-width: 1024px) {
  .home .bg_image_1 {
    height: 600px;
  }

  .home .bg_image_1 ul {
    margin-right: 0;
    margin-left: 0;
  }

  .background-container {
    margin-left: 5%;
    width: 90%;
  }
}

/* Media queries for 480px (smaller screens) */
@media only screen and (max-width: 480px) {
  .home .bg_image_1 {
    height: 450px; /* Adjust height for smaller screens */
  }

  .home .bg_image_1 ul {
    margin-right: 0;
    margin-left: 0;
    flex-direction: column; /* Stack list items vertically for smaller screens */
    align-items: center;
  }

  .home .bg_image_1 ul li {
    margin: 0.5rem;
  }
}

.CivilandInfrastructureDivision{
  overflow: hidden;

}

.CivilandInfrastructureDivision .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.CivilandInfrastructureDivision .bg_image_1 {
  background-image: url("../assest/images/civil-construction.jpg");
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
}

.CivilandInfrastructureDivision .bg_image_1 .content h3 {
  font-size: 4rem;
  color:white;
  padding-top:15% ;
  text-align: center;
  text-shadow: 2px 2px 4px black;
}

.MajorProjectsDivision{
  overflow: hidden;

}

.MajorProjectsDivision .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.MajorProjectsDivision .bg_image_1 {
  background-image: url("../assest/images/MajorProjectsDivision.jpg");
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
}

.MajorProjectsDivision .bg_image_1 .content h3 {
  font-size: 4rem;
  color:white;
  padding-top:15% ;
  text-align: center;
  text-shadow: 2px 2px 4px black;
}

.construction {
  overflow: hidden;
}

.construction .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.construction .bg_image_1 {
  background-image: url("../assest/images/Maintenance_Services.jpg");
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
}

.construction .bg_image_1 .content h3 {
  font-size: 4rem;
  color:white;
  padding-top:15% ;
  text-align: center;
  text-shadow: 2px 2px 4px black;
}

.SupportServicesDivision{
  overflow: hidden;

}

.SupportServicesDivision .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.SupportServicesDivision .bg_image_1 {
  background-image: url("../assest/images/SupportServicesDivision.jpg");
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
}

.SupportServicesDivision .bg_image_1 .content h3 {
  font-size: 4rem;
  color:white;
  padding-top:15% ;
  text-align: center;
  text-shadow: 2px 2px 4px black;
}

.OffshoreServicesDivision{
  overflow: hidden;

}

.OffshoreServicesDivision .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.OffshoreServicesDivision .bg_image_1 {
  background-image: url("../assest/images/OffshoreServicesDivision.jpg");
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
}

.OffshoreServicesDivision .bg_image_1 .content h3 {
  font-size: 4rem;
  color:white;
  padding-top:15% ;
  text-align: center;
  text-shadow: 2px 2px 4px black;
}


.ElectricalInstrumentationDivision{
  overflow: hidden;

}

.ElectricalInstrumentationDivision .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.ElectricalInstrumentationDivision .bg_image_1 {
  background-image: url("../assest/images/ElectricalInstrumentationDivision.jpg");
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
}

.ElectricalInstrumentationDivision .bg_image_1 .content h3 {
  font-size: 4rem;
  color:white;
  padding-top:15% ;
  text-align: center;
  text-shadow: 2px 2px 4px black;
}


.Projects{
  overflow: hidden;

}

.Projects .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.Projects .bg_image_1 {
  background-image: url("../assest/images/project2.webp");
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
}

.Projects .bg_image_1 .content h3 {
  font-size: 4rem;
  color:white;
  padding-top:15% ;
  text-align: center;
  text-shadow: 2px 2px 4px black;
}


@media only screen and (max-width: 480px) {
  .construction .bg_image_1 {
    background-image: url("../assest/images/construction.jpg");
    background-size: cover;
    background-position: center;
    height: 300px;
    width: 100%;
  }
  .imgeLogo{
    height: 50px;
    width: 50px;
    border: 4px;
  }
  
  .construction .bg_image_1 .content h3 {
    font-size: 2.8rem;
    color:white;
    padding-top:15% ;
    text-align: center;
    text-shadow: 2px 2px 4px black;
  }
  .CivilandInfrastructureDivision .bg_image_1 {
    height: 300px;
  }
  
  .CivilandInfrastructureDivision .bg_image_1 .content h3 {
    font-size: 2.8rem;
  }

  .MajorProjectsDivision .bg_image_1 {
    height: 300px;
  }
  
  .MajorProjectsDivision .bg_image_1 .content h3 {
    font-size: 2.8rem;
  }
  .SupportServicesDivision .bg_image_1 {
    height: 300px;
  }
  
  .SupportServicesDivision .bg_image_1 .content h3 {
    font-size: 2.8rem;
  }
  .OffshoreServicesDivision .bg_image_1 {
    height: 300px;
  }
  
  .OffshoreServicesDivision .bg_image_1 .content h3 {
    font-size: 2.8rem;
  }
  .ElectricalInstrumentationDivision .bg_image_1 {
    height: 300px;
  }
  
  .ElectricalInstrumentationDivision .bg_image_1 .content h3 {
    font-size: 2.8rem;
  }

  .Projects .bg_image_1 {
    height: 300px;
  }
  
  .Projects .bg_image_1 .content h3 {
    font-size: 2.8rem;
  }
  
}



.buildtech {
  overflow: hidden;
}

.buildtech .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.buildtech .bg_image_1 {
  background-image: url("../assest/images/buildtech.jpg");
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
}

.buildtech .bg_image_1 .content h3 {
  font-size: 5rem;
  color:white;
  padding-top: 15%;
  text-align: center;
  text-shadow: 2px 2px 4px black;
}

@media only screen and (max-width: 480px) {
  .buildtech .bg_image_1 {
    background-image: url("../assest/images/buildtech.jpg");
    background-size: cover;
    background-position: center;
    height: 400px;
    width: 100%;
  }
  
  .buildtech .bg_image_1 .content h3 {
    font-size: 2.8rem;
    color:white;
    padding-top: 35%;
    text-align: center;
    text-shadow: 2px 2px 4px black;
  }
}

.logistic {
  overflow: hidden;
}

.logistic .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.logistic .bg_image_1 {
  background-image: url("../assest/images/logistic.jpg");
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
}

.logistic .bg_image_1 .content h3 {
  font-size: 5rem;
  color:white;
  padding-top: 15%;
  text-align: center;
  text-shadow: 2px 2px 4px black;
}

@media only screen and (max-width: 480px) {
  .logistic .bg_image_1 {
    background-image: url("../assest/images/logistic.jpg");
    background-size: cover;
    background-position: center;
    height: 400px;
    width: 100%;
  }
  
  .logistic .bg_image_1 .content h3 {
    font-size: 2.8rem;
    color:white;
    padding-top: 35%;
    text-align: center;
    text-shadow: 2px 2px 4px black;
  }
}

.enterprise
 {
  overflow: hidden;
}

.enterprise
 .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.enterprise
 .bg_image_1 {
  background-image: url("../assest/images/enterprise.jpg");
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
}

.enterprise
 .bg_image_1 .content h3 {
  font-size: 5rem;
  color:white;
  padding-top: 15%;
  text-align: center;
  text-shadow: 2px 2px 4px black;
}

@media only screen and (max-width: 480px) {
  .enterprise
 .bg_image_1 {
  background-image: url("../assest/images/enterprise.jpg");
  background-size: cover;
  background-position: center;
  height: 400px;
  width: 100%;
}

.enterprise
 .bg_image_1 .content h3 {
  font-size: 2.8rem;
  color:white;
  padding-top: 25%;
  text-align: center;
  text-shadow: 2px 2px 4px black;
}
}



.implex
 {
  overflow: hidden;
}

.implex
 .bg_image_1 .content {
  bottom: 32.4rem;
  height: 82vh;
}

.implex
 .bg_image_1 {
  background-image: url("../assest/images/implex.jpg");
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
}

.implex
 .bg_image_1 .content h3 {
  font-size: 5rem;
  color:white;
  padding-top: 25%;
  text-align: center;
  text-shadow: 2px 2px 4px black;
}

@media only screen and (max-width: 480px) {
  .implex
 .bg_image_1 {
  background-image: url("../assest/images/implex.jpg");
  background-size: cover;
  background-position: center;
  height: 400px;
  width: 100%;
}

.implex
 .bg_image_1 .content h3 {
  font-size: 2.8rem;
  color:white;
  padding-top: 25%;
  text-align: center;
  text-shadow: 2px 2px 4px black;
}
}

.activenav{
  color:black !important
}

.verticalcenter {
  min-height: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
}
