#BoxId {
    margin: 0 !important;
}

#BoxId .custom-img img {
    height: 100vh !important;
    width: 100%;
    /* filter: brightness(0.6); */
    object-fit: cover;
}

#BoxId .swiper .swiper-slide {
    padding-top: 0 !important;

}

#BoxId .swiper-pagination .swiper-pagination-bullet {
    width: 16px !important;
    height: 6px !important;
    border-radius: 2px !important;
}

#BoxId .swiper-button-prev,
#BoxId .swiper-button-next {
    background-color: rgb(0, 0, 0) !important;
    padding: 10px;
    color: white !important;
    width: 28px !important;
    height: 28px !important;
    z-index: 66 !important;
    box-shadow: 2px 1px 6px rgb(125, 85, 85) !important;
    font-weight: bold;
}

#BoxId .swiper-button-prev {
    border-radius: 50% !important;
    margin: 0 25px;
}

#BoxId .swiper-button-next {
    border-radius: 50% !important;
    margin: 0 26px;
}

#BoxId {
    --swiper-navigation-size: 16px !important;
}

.ServicesDivisiondiv .card{
    height: 420px;
    width: 20rem;
    border-radius: 5px;
}
.ServicesDivisiondiv .card img{
    height: 220px;
   width: 100%;
}
.ServicesDivisiondiv .card-title{
    display: -webkit-box;
    /* Create a flexible box layout */
    -webkit-line-clamp: 1;
    /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    /* Set the box orientation to vertical */
    overflow: hidden;
    /* Hide the overflowing content */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */

}
.ServicesDivisiondiv .card-text{
    display: -webkit-box;
    /* Create a flexible box layout */
    -webkit-line-clamp: 3;
    /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    /* Set the box orientation to vertical */
    overflow: hidden;
    /* Hide the overflowing content */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */

}

.ServicesDivisiondiv button{
    background-color: #fff;
    color: black;
    text-transform: capitalize;
    width: 100%;
}
.ServicesDivisiondiv button:hover{
    background-color: #fff;
    color: black;
}


.aboutDivisiondiv .card{
    height: 445px;
    width: 22rem;
    border-radius: 5px;
    background-color:aliceblue;
    color: black;
}
.aboutDivisiondiv .card img{
    height: 220px;
   width: 100%;
}



.logistic_clients {
    text-align: center;
    width: 100%;
    margin: auto;
    padding: 50px;
    background-color: #eee9e9;
}

.logistic_clients h1 {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 40px;
}

.logistic_clients p {
    font-size: 16px;
}

.Projects_topimg {
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    width: 90% !important;
    padding: 20px;
    background-color: white;
}

.Projects_topimg img {
    height: 100px;
    width: 150px;
    object-fit: contain;
    margin: auto;
    align-items: center;
    border-radius: 4px;
}

.Projects_topimg p {
    font-weight: bold;
    margin-top: 20px;
}



